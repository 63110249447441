const getTrainerFees = (program, rosters, sales) => {
    const sumOfAllRosters = rosters.reduce((total, roster) => {
        return total + roster.rate;
    }, 0); // Initial total is set to 0

    const sumOfAllSalesTeamPercentage = getTotalSalesPercentage(sales);
    let commission_percentage = sumOfAllSalesTeamPercentage / 100
    let overhead = program.program_overhead / 100

    // S1: round(P1 / (1 - Sum of Commission % - Overhead),-3)
    const sellingPrice = roundToNearestThousand(sumOfAllRosters / (1 - commission_percentage - overhead))  

    let projectedCost = sumOfAllRosters
    return {projectedCost, sellingPrice}
}

const getLogisticsMaterials = (program, allowances, cash_advances, sales) => {
    const sumOfAllAllowances = allowances.reduce((total, allowance) => {
        return total + allowance.cost;
    }, 0); // Initial total is set to 0

    const sumOfAllCashAdvances = cash_advances.reduce((total, cash_advance) => {
        return total + cash_advance.cost;
    }, 0); // Initial total is set to 0

    let projectedCost = sumOfAllAllowances + sumOfAllCashAdvances;

    const sumOfAllSalesTeamPercentage = getTotalSalesPercentage(sales);
    let commission_percentage = sumOfAllSalesTeamPercentage / 100
    let overhead = program.program_overhead / 100
    const sellingPrice = roundToNearestThousand(projectedCost / (1 - commission_percentage - overhead))  

    return {projectedCost, sellingPrice}
}

const getOverhead = (program, total) => {
    let overhead = program.program_overhead / 100
    return overhead * total;
}

const getTotal = (trainerFees, logistics_materials, commissions) => {
    let projectedCost = trainerFees.projectedCost + logistics_materials.projectedCost; // + commissions.projectedCost;
    let sellingPrice = trainerFees.sellingPrice + logistics_materials.sellingPrice;
    let sellingPrice_98 = parseFloat(sellingPrice / .98).toFixed(2)

    return {projectedCost, sellingPrice, sellingPrice_98}
}

const getTotalToolPrice = (program) => {
    return parseFloat(program.program_tool_quantity * program.program_tool_price)
}

const getTotalTeamProfilePrice = (program) => {
    return parseFloat(program.program_team_profile_quantity * program.program_team_profile_price)
}

const getContractPriceWithToolAndProfile = (program, toolPrice, teamProfilePrice) => {
    let contract_price = parseFloat(program.program_contract_price);

    return teamProfilePrice + toolPrice + contract_price;
}

const getNetProfitMargin = (program, total) => {
    let netProfitMargin = program.program_contract_price - total.sellingPrice_98;
    let netProfitMarginPercentage = ((netProfitMargin / program.program_contract_price) * 100).toFixed(2);

    return {netProfitMargin, netProfitMarginPercentage}
}

export const programMaths = (program, rosters, sales, cash_advances, allowances) => {
    let trainerFees = getTrainerFees(program, rosters, sales)
    let logistics_materials = getLogisticsMaterials(program, allowances, cash_advances, sales)
    let commissions = {projectedCost: getTotalSalesPercentage(sales)}
    let total = getTotal(trainerFees, logistics_materials, commissions)
    let overhead = getOverhead(program, total.sellingPrice)

    let netProfitMargin = getNetProfitMargin(program, total)

    let totalToolPrice = getTotalToolPrice(program)
    let totalTeamProfilePrice = getTotalTeamProfilePrice(program)
    let totalContractPriceWithToolAndProfile = getContractPriceWithToolAndProfile(program, totalToolPrice, totalTeamProfilePrice)

    return {
        trainerFees, 
        logistics_materials, 
        commissions, 
        overhead, 
        total, 
        netProfitMargin,
        totalToolPrice, 
        totalTeamProfilePrice, 
        totalContractPriceWithToolAndProfile
    }
};

//Reusable functions
const roundToNearestThousand = (num) => {
    const factor = Math.pow(10, -3);
    return Math.round(num * factor) / factor;
}

const getTotalSalesPercentage = (sales) => {
    const sumOfAllSalesTeamPercentage = sales.reduce((total, sale) => {
        return total + sale.sales_percentage;
    }, 0);

    return sumOfAllSalesTeamPercentage;
}
