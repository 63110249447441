import * as Yup from "yup";

import { Link, useParams } from "react-router-dom";
import React, { useState } from "react";
import Select from "react-select";
import { gql, useMutation, useQuery } from "@apollo/client";

import { Layout } from "@components";
import { Switch } from "@headlessui/react";
import { useEffect } from "react";
import { useFormik } from "formik";

// Validation schema
const validationSchema = Yup.object({
  commissionTax: Yup.number()
    .typeError("Commission Tax must be a valid number")
    .required("Roster Tax is required")
    .nullable(),
  email: Yup.string().required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  rosterTax: Yup.number()
    .typeError("Roster Tax must be a valid number")
    .required("Roster Tax is required")
    .nullable(),
  access_level: Yup.number(),
  password: Yup.string(),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

const GET_PERSONNEL_QUERY = gql`
  query ($id: String!) {
    personnel(id: $id) {
      personnel {
        commissionTax
        email
        firstName
        id
        inactiveDate
        lastName
        rosterTax
        user {
          id
          accessLevel
        }
      }
    }
  }
`;

const UPDATE_PERSONNEL_MUTATION = gql`
  mutation (
    $id: String!
    $commissionTax: Float
    $email: String
    $firstName: String
    $lastName: String
    $rosterTax: Float
    $user: UserFields
  ) {
    updatePersonnel(
      id: $id
      commissionTax: $commissionTax
      email: $email
      firstName: $firstName
      lastName: $lastName
      rosterTax: $rosterTax
      user: $user
    ) {
      personnel {
        id
        commissionTax
        email
        firstName
        inactiveDate
        lastName
        rosterTax
        user {
          id
          accessLevel
        }
      }
    }
  }
`;

export default function PersonnelEdit() {
  const [authEnabled, setAuthEnabled] = useState(false);
  const [accessLevel, setAccessLevel] = useState({ label: "User", value: 2 });
  const [accessLevels, setAccessLevels] = useState([
    { label: "Super Admin", value: 0 },
    { label: "Admin", value: 1 },
    { label: "User", value: 2 },
  ]);
  const { id } = useParams();
  const { data } = useQuery(GET_PERSONNEL_QUERY, {
    variables: { id },
  });

  useEffect(() => {
    // Update formik values when data is available
    if (data && data.personnel && data.personnel.personnel) {
      const personnelData = data.personnel.personnel;
      if (personnelData.user?.accessLevel != null) {
        const level = accessLevels.find(
          (al) => al.value == personnelData.user?.accessLevel
        );
        console.log(level);
        if (level) {
          setAccessLevel(level);
        }
      }
      setAuthEnabled(personnelData.user != null);
      formik.setValues({
        id: personnelData.id,
        commissionTax: personnelData.commissionTax,
        email: personnelData.email,
        firstName: personnelData.firstName,
        lastName: personnelData.lastName,
        rosterTax: personnelData.rosterTax,
        access_level: personnelData.user?.accessLevel || 2,
      });
    }
  }, [data]);

  const [update_personnel] = useMutation(UPDATE_PERSONNEL_MUTATION, {
    onCompleted: (data) => {
      if (data && data.updatePersonnel) {
        window.location.replace(`/personnel`);
      } else {
        alert("Something went wrong");
      }
    },
    onError: (error) => {
      alert("Something went wrong");
    },
  });

  const formik = useFormik({
    initialValues: {
      commissionTax: "",
      email: "",
      firstName: "",
      lastName: "",
      rosterTax: "",
      access_level: 2,
      password: null,
      confirmPassword: null,
    },
    validationSchema,
    onSubmit: (values) => {
      update_personnel({
        variables: {
          id: id,
          commissionTax: parseFloat(values.commissionTax),
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          rosterTax: parseFloat(values.rosterTax),
          user: authEnabled
            ? {
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                password: values.password,
                passwordConfirmation: values.confirmPassword,
                accessLevel: parseInt(values.access_level),
              }
            : null,
        },
      });
    },
  });

  return (
    <Layout>
      <div className="pb-10 lg:pl-72">
        <div className="mx-auto max-w-7xl py-10 px-4 sm:px-6 lg:px-8">
          <div>
            <form onSubmit={formik.handleSubmit}>
              <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                  <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                      Personnel Record Entry
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                      Please provide the sales details below.
                    </p>
                  </div>

                  <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                    <div className=" col-span-full">
                      <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="firstName"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            First name <span className="text-red-600">*</span>
                          </label>

                          <div className="mt-2 space-y-2">
                            <input
                              autoFocus
                              id="firstName"
                              name="firstName"
                              type="text"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.firstName}
                              className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />

                            {formik.touched.firstName &&
                            formik.errors.firstName ? (
                              <div className="text-red-600 text-sm">
                                {formik.errors.firstName}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="lastName"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Last name <span className="text-red-600">*</span>
                          </label>

                          <div className="mt-2 space-y-2">
                            <input
                              id="lastName"
                              name="lastName"
                              type="text"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.lastName}
                              className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {formik.touched.lastName &&
                            formik.errors.lastName ? (
                              <div className="text-red-600 text-sm">
                                {formik.errors.lastName}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Email<span className="text-red-600">*</span>
                      </label>

                      <div className="mt-2 space-y-2">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="text-red-600 text-sm">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className=" col-span-full">
                      <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="commissionTax"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Commission tax{" "}
                            <span className="text-red-600">*</span>
                          </label>

                          <div className="mt-2 space-y-2">
                            <input
                              id="commissionTax"
                              name="commissionTax"
                              type="text"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.commissionTax}
                              className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />

                            {formik.touched.commissionTax &&
                            formik.errors.commissionTax ? (
                              <div className="text-red-600 text-sm">
                                {formik.errors.commissionTax}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="rosterTax"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Roster tax <span className="text-red-600">*</span>
                          </label>

                          <div className="mt-2 space-y-2">
                            <input
                              id="rosterTax"
                              name="rosterTax"
                              type="text"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.rosterTax}
                              className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {formik.touched.rosterTax &&
                            formik.errors.rosterTax ? (
                              <div className="text-red-600 text-sm">
                                {formik.errors.rosterTax}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-full">
                      <div className="flex items-center">
                        <Switch
                          checked={authEnabled}
                          onChange={setAuthEnabled}
                          className={`relative inline-flex h-6 w-11 items-center rounded-full ${
                            authEnabled ? "bg-indigo-600" : "bg-gray-200"
                          } transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                        >
                          <span className="sr-only">
                            Enable auth credentials
                          </span>
                          <span
                            className={`inline-block h-5 w-5 transform rounded-full bg-white transition-transform duration-200 ease-in-out ${
                              authEnabled ? "translate-x-5" : "translate-x-0"
                            }`}
                          />
                        </Switch>

                        <label
                          htmlFor="authToggle"
                          className="block ml-2 text-sm font-medium leading-6 text-gray-900"
                        >
                          Enable Auth Credentials
                        </label>
                      </div>
                    </div>

                    {authEnabled && (
                      <>
                        <div className="col-span-full">
                          <label
                            htmlFor="personnel_id"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Access Level <span className="text-red-600">*</span>
                          </label>

                          <div className="mt-2 space-y-2">
                            <Select
                              options={accessLevels}
                              name="access_level"
                              id="access_level"
                              instanceId="access_level"
                              value={{
                                label: accessLevel.label,
                                value: accessLevel.value,
                              }}
                              onChange={(selected) => {
                                formik.setFieldValue(
                                  "access_level",
                                  selected.value
                                );
                                setAccessLevel(selected);
                              }}
                              className="block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {formik.touched.personnel_id &&
                            formik.errors.personnel_id ? (
                              <div className="text-red-600 text-sm">
                                {formik.errors.personnel_id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-span-full">
                          <label
                            htmlFor="password"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Password <span className="text-red-600">*</span>
                          </label>

                          <div className="mt-2 space-y-2">
                            <input
                              id="password"
                              name="password"
                              type="password"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.password}
                              className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {formik.touched.password &&
                            formik.errors.password ? (
                              <div className="text-red-600 text-sm">
                                {formik.errors.password}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-span-full">
                          <label
                            htmlFor="confirmPassword"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Confirm Password{" "}
                            <span className="text-red-600">*</span>
                          </label>

                          <div className="mt-2 space-y-2">
                            <input
                              id="confirmPassword"
                              name="confirmPassword"
                              type="password"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.confirmPassword}
                              className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {formik.touched.confirmPassword &&
                            formik.errors.confirmPassword ? (
                              <div className="text-red-600 text-sm">
                                {formik.errors.confirmPassword}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-6 flex items-center justify-end gap-x-6">
                <Link
                  to={`/personnel`}
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  Cancel
                </Link>
                <button
                  type="submit"
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
}
