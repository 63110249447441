import * as Yup from "yup";

import { Layout, ModalAlert } from "@components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useRef, useState } from "react";

import CurrencyInput from "react-currency-input-field";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Header from "../../../components/Header";
import Select from "react-select";
import formatNumber from "../../../utils/formatNumber";
import moment from "moment";
import { useFormik } from "formik";

// Validation schema
const validationSchema = Yup.object({
  program_id: Yup.string().required("Required"),
  role_type_id: Yup.string().required("Required"),
  personnel_id: Yup.string().required("Required").nullable(),
  roster_date: Yup.date().required("Required").nullable(),
  rate: Yup.number().required("Required"),
  numberOfHours: Yup.number().required("Required"),
  description: Yup.string(),
  remarks: Yup.string(),
});

const SELECT_FIELD_QUERY = gql`
  query ($id: String!) {
    program(id: $id) {
      program {
        id
        clientName
        clientDepartment
        clientContactName
        clientContactDetails
        programVenue
        programPax
        programTypeIndoorModular
        programTypeTeambuilding
        programTypeConsultation
        programTypeIndoorConvention
        programEmtCost
        programToolName
        programToolCost
        programToolPrice
        programToolQuantity
        programOverhead
        programContractPrice
        totalInvoicePrice
        isInvoiceEqualToContractPrice
        totalContractPrice
      }
    }
    references {
      status
      references {
        id
        referenceCode
        referenceType
        referenceDescription
      }
    }
    personnels {
      status
      personnels {
        id
        email
        firstName
        lastName
      }
    }
    allRosters(programId: $id) {
      rosters {
        id
        roleType {
          id
          referenceCode
          referenceType
          referenceDescription
        }
        personnel {
          id
          firstName
          lastName
        }
        rosterDate
        paidDate
        rate
        description
        remarks
        status
        paidDate
      }
    }
  }
`;

const CREATE_ROSTER_MUTATION = gql`
  mutation (
    $personnelId: String
    $programId: String
    $roleTypeId: String
    $rosterDate: String
    $rate: Float
    $numberOfHours: Float
    $description: String
    $remarks: String
  ) {
    createRoster(
      personnelId: $personnelId
      programId: $programId
      roleTypeId: $roleTypeId
      rosterDate: $rosterDate
      rate: $rate
      numberOfHours: $numberOfHours
      description: $description
      remarks: $remarks
    ) {
      roster {
        id
        personnel {
          firstName
        }
        roleType {
          referenceDescription
        }
        rosterDate
        rate
        numberOfHours
        description
        remarks
      }
    }
  }
`;

export default function RosterAddMode() {
  const [programData, setProgramData] = useState();
  const [activeTab, setActiveTab] = useState("Rosters");
  const [rostersData, setRostersData] = useState([]);
  const [rostersTypeId, setRostersTypeId] = useState({ label: "", value: "" });
  const [personnelId, setPersonnelId] = useState({ label: "", value: "" });
  const [showModal, setShowModal] = useState(false);
  const [isAddNew, setAddNew] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const cancelButtonRef = useRef(null);
  //const { data } = useQuery(SELECT_FIELD_QUERY, { variables: { id } });
  const { data, refetch } = useQuery(SELECT_FIELD_QUERY, { variables: { id } });
  const [rostersTypeIdOptions, setRostersTypeOptions] = useState([]);
  const [personnelIdOptions, setPersonnelOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notification, setNotification] = useState({
    message: "",
    isVisible: false,
  });

  const [create_roster] = useMutation(CREATE_ROSTER_MUTATION, {
    onCompleted: (data) => {
      if (data && data.createRoster) {
        //window.location.replace(`/programs/${id}?module=Rosters`);
        if (!isAddNew) {
          navigate(`/programs/${id}?module=Rosters`);
        } else {
          refetch();
        }
        setNotification({
          message: "Roster was successfully saved.",
          isVisible: true,
        });
        setTimeout(() => {
          setNotification({ message: "", isVisible: false });
          //navigate(`/programs/${id}?module=Rosters`);
        }, 3000); // Hide after 3 seconds
      } else {
        alert("Something went wrong");
      }
    },
    onError: (error) => {
      alert("Something went wrong!");
    },
  });

  useEffect(() => {
    if (data) {
      let program = data.program.program;
      setProgramData(program);

      let references = data?.references?.references
        .filter(
          (reference) => reference && reference.referenceType === "roster"
        ) // Ensure reference is not null
        .map((reference) => ({
          value: reference.id,
          label: reference.referenceDescription || "", // Fallback to empty string if undefined
        }));

      let personnels = data?.personnels?.personnels.map((personnel) => ({
        value: personnel.id,
        label: `${personnel.firstName} ${personnel.lastName}`,
      }));

      // Add "N/A" option to the personnels array
      personnels = [{ value: null, label: "N/A" }, ...personnels];

      let rosters = data.allRosters.rosters.map((roster) => ({
        id: roster.id,
        role_type: roster.roleType?.referenceDescription || "N/A",
        personnel: roster.personnel
          ? `${roster.personnel.firstName} ${roster.personnel.lastName}`
          : "N/A",
        roster_date: roster.rosterDate,
        rate: roster.rate,
        description: roster.description,
        remarks: roster.remarks,
      }));

      setRostersData(rosters);

      setRostersTypeOptions(references || []);
      setPersonnelOptions(personnels || []);
      setPersonnelId({ value: null, label: "N/A" }); // Set default to "N/A"
    }
  }, [data]);

  const tabs = [
    {
      name: "Sales Team",
      href: `/programs/${id}`,
      current: activeTab === "Sales Team",
    },
    {
      name: "Rosters",
      href: `/programs/${id}`,
      current: activeTab === "Rosters",
    },
    {
      name: "Allowances",
      href: `/programs/${id}`,
      current: activeTab === "Allowances",
    },
    {
      name: "Advances",
      href: `/programs/${id}`,
      current: activeTab === "Advances",
    },
    {
      name: "Invoices",
      href: `/programs/${id}`,
      current: activeTab === "Invoices",
    },
  ];

  const handleLeaveClick = (e, tabName) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleLeave = (decision) => {
    setShowModal(false);
    if (decision === "yes") {
      navigate(`/programs/${id}`);
      setShowModal(false);
    } else {
      setShowModal(false);
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const formik = useFormik({
    initialValues: {
      program_id: id || "",
      role_type_id: "",
      personnel_id: null,
      roster_date: "",
      rate: "",
      numberOfHours: "0",
      description: "",
      remarks: "",
    },
    validationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      setIsSubmitting(true);
      create_roster({
        variables: {
          programId: values.program_id,
          roleTypeId: values.role_type_id,
          personnelId: values.personnel_id,
          rosterDate: values.roster_date,
          rate: parseFloat(values.rate),
          numberOfHours: parseFloat(values.numberOfHours),
          description: values.description,
          remarks: values.remarks,
        },
      })
        .then(() => {
          formik.resetForm();
          setIsSubmitting(false);
        })
        .catch(() => {
          setIsSubmitting(false);
        });
    },
  });

  const handleBeforeUnload = (e) => {
    if (formik.dirty && !isSubmitting) {
      e.preventDefault();
      e.returnValue = ""; // Standard message
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formik.dirty, isSubmitting]);

  const handleNavigationWithWarning = (to) => {
    if (formik.dirty && !isSubmitting) {
      const confirmLeave = window.confirm(
        "You have unsaved changes. Are you sure you want to leave?"
      );
      if (confirmLeave) {
        navigate(to);
      }
    } else {
      navigate(to);
    }
  };

  return (
    <Layout>
      {notification.isVisible && (
        <div className="fixed top-0 left-0 right-0 bg-green-500 text-white text-center py-4 z-50">
          {notification.message}
        </div>
      )}
      <div className="pb-10 lg:pl-72">
        <Header program={programData} />
        <div className="sm:hidden mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            value={activeTab}
            onChange={(e) => handleLeaveClick(e.target.value)}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav
              className="-mb-px flex space-x-8 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 "
              aria-label="Tabs"
            >
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  onClick={(e) => handleLeaveClick(e, tab.name)}
                  className={classNames(
                    tab.current
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>

        {/* Conditional rendering based on the activeTab */}
        <div className="mx-auto max-w-7xl py-10 px-4 sm:px-6 lg:px-8">
          {activeTab === "Rosters" && (
            <div>
              <form onSubmit={formik.handleSubmit}>
                <div className="space-y-12">
                  <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Rosters Record Entry
                      </h2>
                      <p className="mt-1 text-sm leading-6 text-gray-600">
                        Please provide the rosters details below.
                      </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                      <div className=" col-span-full">
                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="react-select-role_type_id-input"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Role <span className="text-red-600">*</span>
                            </label>

                            <div className="mt-2 space-y-2">
                              <Select
                                autoFocus
                                options={rostersTypeIdOptions}
                                name="role_type_id"
                                id="role_type_id"
                                instanceId="role_type_id"
                                value={{
                                  label: rostersTypeId.label,
                                  value: rostersTypeId.value,
                                }}
                                onChange={(selected) => {
                                  formik.setFieldValue(
                                    "role_type_id",
                                    selected.value
                                  );
                                  setRostersTypeId(selected);
                                }}
                                className="block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              {formik.touched.role_type_id &&
                              formik.errors.role_type_id ? (
                                <div className="text-red-600 text-sm">
                                  {formik.errors.role_type_id}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="sm:col-span-3">
                            <label
                              htmlFor="react-select-personnel_id-input"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Name <span className="text-red-600">*</span>
                            </label>

                            <div className="mt-2 space-y-2">
                              <Select
                                options={personnelIdOptions}
                                name="personnel_id"
                                id="personnel_id"
                                instanceId="personnel_id"
                                value={{
                                  label: personnelId.label,
                                  value: personnelId.value || "",
                                }}
                                onChange={(selected) => {
                                  formik.setFieldValue(
                                    "personnel_id",
                                    selected.value
                                  );
                                  setPersonnelId(selected);
                                }}
                                className="block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              {formik.touched.personnel_id &&
                              formik.errors.personnel_id ? (
                                <div className="text-red-600 text-sm">
                                  {formik.errors.personnel_id}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className=" col-span-full">
                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-9 md:col-span-3">
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="roster_date"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Roster Date{" "}
                              <span className="text-red-600">*</span>
                            </label>

                            <div className="mt-2 space-y-2">
                              <input
                                id="roster_date"
                                name="roster_date"
                                type="date"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                max="9999-12-31"
                                value={formik.values.roster_date}
                                className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              {formik.touched.roster_date &&
                              formik.errors.roster_date ? (
                                <div className="text-red-600 text-sm">
                                  {formik.errors.roster_date}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="sm:col-span-3">
                            <label
                              htmlFor="rate"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Rate <span className="text-red-600">*</span>
                            </label>

                            <div className="mt-2 space-y-2">
                              <CurrencyInput
                                id="rate"
                                name="rate"
                                value={formik.values.rate}
                                decimalsLimit={2}
                                onValueChange={(value, name, values) =>
                                  formik.setFieldValue("rate", value)
                                }
                                className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              {formik.touched.rate && formik.errors.rate ? (
                                <div className="text-red-600 text-sm">
                                  {formik.errors.rate}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="rate"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Number of hours{" "}
                              <span className="text-red-600">*</span>
                            </label>

                            <div className="mt-2 space-y-2">
                              <CurrencyInput
                                id="numberOfHours"
                                name="numberOfHours"
                                value={formik.values.numberOfHours}
                                decimalsLimit={2}
                                onValueChange={(value, name, values) =>
                                  formik.setFieldValue("numberOfHours", value)
                                }
                                className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              {formik.touched.numberOfHours &&
                              formik.errors.numberOfHours ? (
                                <div className="text-red-600 text-sm">
                                  {formik.errors.numberOfHours}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-span-full">
                        <label
                          htmlFor="description"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Description
                        </label>

                        <div className="mt-2 space-y-2">
                          <textarea
                            rows={3}
                            id="description"
                            name="description"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.description}
                            className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          {formik.touched.description &&
                          formik.errors.description ? (
                            <div className="text-red-600 text-sm">
                              {formik.errors.description}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-span-full">
                        <label
                          htmlFor="remarks"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Remarks
                        </label>

                        <div className="mt-2 space-y-2">
                          <textarea
                            rows={3}
                            id="remarks"
                            name="remarks"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.remarks}
                            className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          {formik.touched.remarks && formik.errors.remarks ? (
                            <div className="text-red-600 text-sm">
                              {formik.errors.remarks}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <input
                  type="hidden"
                  name="isAddNew"
                  value={isAddNew || false}
                />
                <div className="mt-6 flex items-center justify-end gap-x-6">
                  <Link
                    to={`/programs/${id}?module=Rosters`}
                    className="text-sm font-semibold leading-6 text-gray-900"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default navigation
                      handleNavigationWithWarning(
                        `/programs/${id}?module=Rosters`
                      );
                    }}
                  >
                    Cancel
                  </Link>

                  <button
                    type="submit"
                    onClick={() => {
                      setAddNew(false);
                    }}
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setAddNew(true);
                      formik.handleSubmit();
                    }}
                    className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    Save and Add New
                  </button>
                </div>
              </form>
            </div>
          )}

          {rostersData.length != 0 ? (
            <div className="mx-auto max-w-7xl py-5 px-4 sm:px-6 lg:px-8 border-t m-5">
              <table className="w-full text-left">
                <thead className="bg-white">
                  <tr>
                    <th
                      scope="col"
                      className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 whitespace-nowrap"
                    >
                      Role
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                    >
                      Rate
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                    >
                      Remarks
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rostersData &&
                    rostersData.map((roster) => (
                      <tr
                        key={roster.id}
                        className="cursor-pointer hover:bg-gray-50 border-t border-b"
                      >
                        <td className="relative py-4 pr-3 text-sm font-medium text-gray-900 capitalize">
                          {roster.role_type}
                        </td>
                        <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                          {roster.personnel}
                        </td>
                        <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                          {moment(roster.roster_date).format("YYYY-MMM-DD")}
                        </td>
                        <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                          {formatNumber(roster.rate)}
                        </td>
                        <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                          {roster.description}
                        </td>
                        <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                          {roster.remarks}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
      </div>
      {showModal && (
        <>
          <ModalAlert open={showModal} setOpen={setShowModal}>
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationTriangleIcon
                  className="h-6 w-6 text-red-600"
                  aria-hidden="true"
                />
              </div>
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h3
                  as="h3"
                  className="text-base font-semibold leading-6 text-gray-900"
                >
                  Leave Edit Mode
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Do you want to exit edit mode? Your unsaved changes might be
                    lost.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                onClick={() => handleLeave("yes")}
              >
                Yes
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={() => handleLeave("no")}
                ref={cancelButtonRef}
              >
                No
              </button>
            </div>
          </ModalAlert>
        </>
      )}
    </Layout>
  );
}
