import "react-toastify/dist/ReactToastify.css";

import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import Filter from "./filter";
import { Layout } from "@components";
import Loader from "../../components/Loader";
import ReactPaginate from "react-paginate";
import StatusSelect from "./status";
import { ToastContainer } from "react-toastify";
import formatNumber from "../../utils/formatNumber";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const ADMIN_INVOICES_QUERY = gql`
  query ($page: Int, $startDate: String, $endDate: String, $status: String) {
    adminInvoices(
      page: $page
      startDate: $startDate
      endDate: $endDate
      status: $status
    ) {
      entries {
        billedTo
        changedBy {
          id
          email
        }
        id
        invoiceDate
        price
        program {
          id
          clientName
          clientContactName
          clientContactDetails
        }
        status
        statusDate
        updatedAt
      }
      pageNumber
      pageSize
      totalPages
      totalEntries
    }
  }
`;

export default function Invoices() {
  const [page, setPage] = useState(1);
  const [queryFilters, setQueryFilters] = useState({ page: 1 });

  const { loading, error, data, refetch } = useQuery(ADMIN_INVOICES_QUERY, {
    variables: { page },
  });

  useEffect(() => {
    refetch(queryFilters);
  }, [queryFilters]);

  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    refetch({ page: e.selected + 1 });
  };

  if (loading) return <Loader />;
  if (error) return <h1>Something went wrong!</h1>;

  const startOfResults = data.adminInvoices.pageNumber
    ? (data.adminInvoices.pageNumber - 1) * data.adminInvoices.pageSize + 1
    : 0;
  const endOfResults = data.adminInvoices.totalEntries
    ? Math.min(
        startOfResults + data.adminInvoices.pageSize - 1,
        data.adminInvoices.totalEntries
      )
    : 0;

  return (
    <Layout>
      <ToastContainer />
      <div className="py-10 lg:pl-72">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Invoices
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the invoices
              </p>
            </div>
          </div>
        </div>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-5">
          <Filter
            setQueryFilters={setQueryFilters}
            setPage={setPage}
            filter={refetch}
          />
        </div>
        <div className="mt-8 flow-root overflow-hidden">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <table className="w-full text-left">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Invoice Date
                    <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                    <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Program Name
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Contact Person
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Contact Details
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                  >
                    Billed To
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                  >
                    Updated On
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                  >
                    Updated By
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.adminInvoices.entries.map((item) => (
                  <tr id={item.id} key={item.id} className="cursor-pointer hover:bg-gray-50">
                    <td className="relative py-4 pr-3 text-sm font-medium text-gray-900">
                      {moment(item.invoiceDate).format("YYYY-MMM-DD")}
                      <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                      <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {item?.program?.clientName}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {item?.program?.clientContactName}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {item?.program?.clientContactDetails}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {item?.billedTo}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                      {formatNumber(item?.price)}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                      {
                      item.statusDate
                      ? moment(item.statusDate).format("YYYY-MMM-DD HH:mm.ss")
                      : "N/A"
                      }
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {item.changedBy?.email}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      <div className="w-40">
                        {" "}
                        {/* Adjust width here */}
                        <StatusSelect
                          id={item.id}
                          selectedStatus={item.status}
                          refetch={refetch}
                          error={error}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {data && (
              <div className="py-4 flex flex-row items-center justify-between mt-6">
                <div className="text-sm text-gray-700">
                  <p>
                    Showing{" "}
                    <span className="font-medium">{startOfResults}</span> to{" "}
                    <span className="font-medium">{endOfResults}</span> of{" "}
                    <span className="font-medium">
                      {data.adminInvoices.totalEntries}
                    </span>{" "}
                    results
                  </p>
                </div>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <span>
                      <span className="sr-only">Next</span>
                      <svg
                        className="h-5 w-5 ml-2"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  }
                  previousLabel={
                    <span>
                      <svg
                        className="h-5 w-5 mr-2"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="sr-only">Previous</span>
                    </span>
                  }
                  breakLinkClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 cursor-not-allowed"
                  pageClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  containerClassName="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  activeClassName="z-10 bg-indigo-600 text-white ring-1 ring-indigo-600"
                  previousLinkClassName="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  nextLinkClassName="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={data.adminInvoices.totalPages}
                  renderOnZeroPageCount={null}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
