import {
  BanknotesIcon,
  Bars3Icon,
  DocumentDuplicateIcon,
  UsersIcon,
  UserIcon,
  XMarkIcon,
  ChartBarIcon,
  ReceiptPercentIcon,
  ReceiptRefundIcon,
  WalletIcon,
  CogIcon,
} from "@heroicons/react/24/outline";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";

import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

const allNavigation = [
  { name: "Program", href: "/programs", icon: DocumentDuplicateIcon },
  { name: "Cash Advance", href: "/cash-advance", icon: ReceiptRefundIcon },
  { name: "Roster Payables", href: "/roster-payables", icon: UsersIcon },
  {
    name: "Allowance",
    href: "/allowance",
    icon: WalletIcon,
  },
  { name: "Invoices", href: "/invoices", icon: BanknotesIcon },
  {
    name: "Sale Comission Payables",
    href: "/sale-comission-payables",
    icon: ReceiptPercentIcon,
  },
  { name: "Personnel", href: "/personnel", icon: UserIcon },
  {
    name: "Report",
    href: "/report",
    icon: ChartBarIcon,
  },
  {
    name: "Settings",
    href: "/settings",
    icon: CogIcon,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const GET_USER_INFO_QUERY = gql`
  query {
    getUserInfo {
      status
      user {
        id
        email
      }
    }
    getUserAcess {
      status
      access
    }
  }
`;

const Loader = () => {
  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <div className="flex-shrink-0">
        <img
          src="/images/gif/wired-outline-12-layers.gif"
          alt="loading"
          className="mx-auto h-auto max-h-28 w-28"
        />
      </div>
      <p className="text-gray-500 text-xs pt-2">Loading...</p>
    </div>
  );
};

export default function Layout({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [navigation, setNavigation] = useState([]);
  const location = useLocation();
  const { data, loading, error } = useQuery(GET_USER_INFO_QUERY);

  useEffect(() => {
    let allowedTabs = data?.getUserAcess?.access || [];

    // Filter navigation based on allowed tabs
    const updatedNavigation = allNavigation
      .filter((item) => allowedTabs.includes(item.name))
      .map((item) => ({
        ...item,
        current:
          item.href === "/programs" &&
          (location.pathname === "/programs" || location.pathname === "/home")
            ? true
            : location.pathname === item.href, // Set 'current' property based on current location
      }));

    // Update navigation state
    setNavigation(updatedNavigation);
  }, [location, data]); // Trigger useEffect when location changes

  if (loading) return <Loader />;
  if (error) return <p>Error loading user info</p>;

  const email = data?.getUserInfo?.user?.email || "user@example.com";

  const getInitials = (email) => {
    const [namePart, domainPart] = email.split("@");
    const initials = namePart[0].toUpperCase() + domainPart[0].toUpperCase();
    return initials;
  };

  const initials = getInitials(email);

  const clearCacheOnLogout = () => {
    localStorage.clear(); // Clear all items from localStorage
    // sessionStorage.clear(); // Use this line if you prefer to clear sessionStorage instead
    window.location.href = "/"; // Redirect to the root path
  };

  const handleLogout = () => {
    // Perform logout logic (e.g., clear user session, redirect to login page, etc.)
    // Then call the function to delete the cache
    clearCacheOnLogout();
  };

  const ProfileButton = ({ email, initials }) => (
    <button className="flex items-center gap-x-4 px-6 py-3 w-full text-sm font-semibold leading-6 text-white hover:bg-green-700">
      <div className="h-8 w-8 rounded-full bg-green-700 flex items-center justify-center text-white">
        {initials}
      </div>
      <span className="sr-only">Your profile</span>
      <span aria-hidden="true">{email}</span>
    </button>
  );

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-green-600 px-6 pb-2">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src="/images/png/NEXT-STEP-LOGO-black.png"
                        alt="NEXT STEP"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <a
                                  href={item.href}
                                  className={classNames(
                                    item.current
                                      ? "bg-green-700 text-white"
                                      : "text-green-200 hover:text-white hover:bg-green-700",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.current
                                        ? "text-white"
                                        : "text-green-200 group-hover:text-white",
                                      "h-6 w-6 shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-green-600 px-6">
            <div className="flex h-16 shrink-0 items-center">
              <img
                className="h-8 w-auto"
                src="/images/png/NEXT-STEP-LOGO-black.png"
                alt="NEXT STEP"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-green-700 text-white"
                              : "text-green-200 hover:text-white hover:bg-green-700",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-white"
                                : "text-green-200 group-hover:text-white",
                              "h-6 w-6 shrink-0"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>

                <li className="-mx-6 mt-auto w-full">
                  <button
                    onClick={handleLogout}
                    className="flex items-center gap-x-4 px-6 py-3 w-full text-sm font-semibold leading-6 text-white hover:bg-green-700"
                  >
                    Logout
                  </button>
                </li>

                <li className="-mx-6 -mt-8 w-full">
                  <ProfileButton email={email} initials={initials} />
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-green-600 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-green-200 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-white">
            Dashboard
          </div>
          <ProfileButton email={email} initials={initials} />
        </div>

        <main>{children}</main>
      </div>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
