import * as Yup from "yup";

import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import Select from "react-select";
import { useFormik } from "formik";

const FILTER_QUERY = gql`
  query {
    rosterReportParams {
      status
      personnels {
        id
        firstName
        lastName
      }
      references {
        id
        referenceCode
        referenceDescription
      }
    }
  }
`;

// Validation schema
const validationSchema = Yup.object({
  personnel_id: Yup.string().when("reference", {
    is: (reference) => {
      console.log(reference);
      // Ensure reference is an array and has values
      return Array.isArray(reference) && reference.length > 0;
    },
    then: Yup.string().required(
      "Personnel is required when reference has values"
    ),
    otherwise: Yup.string(),
  }),
  reference: Yup.array().of(Yup.string()),
});

export default function Filter(props) {
  const { loading, error, data, refetch } = useQuery(FILTER_QUERY, {
    variables: { program: "all", personnel: "all", status: "all" },
  });

  const [personnelOptions, setPersonnelOptions] = useState([
    { label: "All", value: null },
  ]);

  const [referenceOptions, setReferenceOptions] = useState([
    { label: "All", value: null },
  ]);
  const [clientName, setClientName] = useState({ label: "All", value: "all" });
  const [personnelId, setPersonnelId] = useState({
    label: "All",
    value: "all",
  });

  useEffect(() => {
    if (data && data.rosterReportParams) {
      const defaultOptions = [{ label: "All", value: null }];
      let personnels = data.rosterReportParams.personnels.map((personnel) => ({
        label: `${personnel.firstName} ${personnel.lastName}`,
        value: personnel.id,
      }));
      setPersonnelOptions([...defaultOptions, ...personnels]);

      let references = data.rosterReportParams.references.map((reference) => ({
        label: reference.referenceDescription,
        value: reference.id,
      }));
      setReferenceOptions([...references]);
    }
  }, [data]);

  const formik = useFormik({
    initialValues: {
      personnel_id: "",
      reference: [],
    },
    // validationSchema,
    onSubmit: (values) => {
      props.filter({
        references: values.reference,
        personnelId: values.personnel_id,
      });
    },
  });

  if (loading) return null;
  if (error) return `Error! ${error.message}`;

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="space-y-12">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2 md:col-span-2 items-end">
            <div>
              <label
                htmlFor="personnel_id"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Personnel <span className="text-red-600">*</span>
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  options={personnelOptions}
                  name="personnel_id"
                  id="personnel_id"
                  instanceId="personnel_id"
                  value={{
                    label: personnelId.label,
                    value: personnelId.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("personnel_id", selected.value);
                    setPersonnelId(selected);
                  }}
                  className="block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {formik.touched.personnel_id && formik.errors.personnel_id ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.personnel_id}
                  </div>
                ) : null}
              </div>
            </div>

            <div>
              <label
                htmlFor="reference"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                References <span className="text-red-600">*</span>
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  isMulti
                  options={referenceOptions}
                  name="reference"
                  id="reference"
                  instanceId="reference"
                  value={formik.values.reference.map((value) => ({
                    label: referenceOptions.find(
                      (option) => option.value === value
                    )?.label,
                    value,
                  }))}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions.map(
                      (option) => option.value
                    );
                    formik.setFieldValue("reference", selectedValues);
                  }}
                  className="block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {formik.touched.reference && formik.errors.reference ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.reference}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="mt-4 bg-indigo-600 text-white py-2 px-4 rounded hover:bg-indigo-700"
        >
          Submit
        </button>
      </form>
    </div>
  );
}
