import * as Yup from "yup";

import { forwardRef, useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";

import { CalendarIcon } from "@heroicons/react/24/solid";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { useFormik } from "formik";

const PROGRAM_FILTER = gql`
  query {
    allowanceFilter {
      personnels {
        id
        firstName
        lastName
      }
      programs {
        id
        programName
      }
      statuses
    }
  }
`;

// Validation schema
const validationSchema = Yup.object({
  start_date: Yup.string(),
  end_date: Yup.string(),
  personnel_name: Yup.string(),
  program_name: Yup.string(),
  program_status: Yup.string(),
});

export default function Filter(props) {
  const { loading, error, data, refetch } = useQuery(PROGRAM_FILTER);
  const [programName, setProgramName] = useState({
    label: "All",
    value: "all",
  });
  const [clientName, setClientName] = useState({ label: "All", value: "all" });
  const [statusOptions, setStausOptions] = useState([
    { label: "All", value: "all" },
    { label: "Active", value: "active" },
    { label: "Expired", value: "expired" },
    { label: "Liquidated", value: "liquidated" },
  ]);

  const [status, setStatus] = useState({
    label: "Active",
    value: "active",
  });

  const [clientNameOptions, setClientNameOptions] = useState([]);
  const [programNameOptions, setProgramNameOptions] = useState([]);

  useEffect(() => {
    // Update formik values when data is available
    if (data && data.allowanceFilter) {
      let programs = data.allowanceFilter.programs.map((program) => ({
        label: program.programName,
        value: program.id,
      }));
      setProgramNameOptions([...[{ label: "All", value: "all" }], ...programs]);

      let clients = data.allowanceFilter.personnels.map((personnel) => ({
        label: `${personnel.firstName} ${personnel.lastName}`,
        value: personnel.id,
      }));
      setClientNameOptions([...[{ label: "All", value: "all" }], ...clients]);
    }
  }, [data]);

  const currentDate = new Date().toISOString().split("T")[0];

  const formik = useFormik({
    initialValues: {
      personnel_name: "all",
      program_name: "all",  
      program_status: "active",
      end_date: currentDate,
    },
    validationSchema,
    onSubmit: (values) => {
      // console.log({
      //   variables: {
      //     personnel_name: values.personnel_name,
      //     program_name: values.program_name,
      //     program_status: values.program_status,
      //   },
      // });
    },
  });

  useEffect(() => {
    let formFields = formik.values;
    console.log(formFields);
    props.filter({
      page: 1,
      startDate: parseValue(formFields.start_date),
      endDate: parseValue(formFields.end_date),
      status: status.value,
      personnelId: formFields.personnel_name,
      program: formFields.program_name,
    });
    props.setPage(1);
  }, [
    formik.values.start_date,
    formik.values.end_date,
    formik.values.personnel_name,
    formik.values.program_name,
    status,
  ]);

  const parseValue = (string) => {
    if (string == "") {
      return null;
    } else if (string == null) {
      return null;
    } else if (string == undefined) {
      return null;
    } else {
      return string;
    }
  };

  // Custom input component to include calendar icon
  const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <div
      onClick={onClick}
      className="relative w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 flex items-center"
    >
      <input
        ref={ref}
        value={value}
        onChange={onChange}
        className="flex-grow outline-none w-full"
        placeholder="Select date"
        readOnly
      />
      <CalendarIcon className="h-5 w-5 text-gray-400 ml-2" />
    </div>
  ));

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="space-y-12">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-10 md:col-span-2 items-start">
            <div className="sm:col-span-2">
              <label
                htmlFor="start_date"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Start date
              </label>

              <div className="mt-2 space-y-2">
                <input
                  autoFocus
                  id="start_date"
                  name="start_date"
                  type="date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.start_date}
                  className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="end_date"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                End date
              </label>

              <div className="mt-2 space-y-2">
                <input
                  id="end_date"
                  name="end_date"
                  type="date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.end_date}
                  className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                {/* {formik.touched.end_date && formik.errors.end_date ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.end_date}
                  </div>
                ) : null} */}
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="react-select-personnel_name-input"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Name
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  autoFocus
                  options={clientNameOptions}
                  name="personnel_name"
                  id="personnel_name"
                  instanceId="personnel_name"
                  value={{
                    label: clientName.label,
                    value: clientName.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("personnel_name", selected.value);
                    setClientName(selected);
                  }}
                  className="block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                {formik.touched.personnel_name &&
                formik.errors.personnel_name ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.personnel_name}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="react-select-program_name-input"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Program Name
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  options={programNameOptions}
                  name="program_name"
                  id="program_name"
                  instanceId="program_name"
                  value={{
                    label: programName.label,
                    value: programName.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("program_name", selected.value);
                    setProgramName(selected);
                  }}
                  className="block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                {formik.touched.personnel_name && formik.errors.program_name ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.program_name}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="react-select-program_status-input"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Status
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  options={statusOptions}
                  name="program_status"
                  id="program_status"
                  instanceId="program_status"
                  value={{
                    label: status.label,
                    value: status.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("program_status", selected.value);
                    setStatus(selected);
                  }}
                  className="capitalize block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {formik.touched.program_status &&
                formik.errors.program_status ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.program_status}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
