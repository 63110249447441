import * as Yup from "yup";

import { Layout, ModalAlert } from "@components";
import { Link, useNavigate } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react"; // Import useState hook

import CurrencyInput from "react-currency-input-field";
import Filter from "./filter";
import Loader from "../../components/Loader";
import ReactPaginate from "react-paginate";
import formatNumber from "../../utils/formatNumber";
import moment from "moment";
import { useFormik } from "formik";

const validationSchema = Yup.object({
  paidDate: Yup.date().required("Required"),
  salesAmount: Yup.string().required("Required"),
  salesTaxAmount: Yup.string().required("Required"),
});

const ADMIN_SALES_QUERY = gql`
  query ($page: Int, $status: String) {
    adminSales(page: $page, status: $status) {
      entries {
        clientName
        commission
        commissionRate
        contractPrice
        date
        id
        name
        netAmount
        programName
        salesType
        status
        tax
        taxAmount
        paidDate
      }
      pageNumber
      pageSize
      totalPages
      totalEntries
    }
  }
`;

const SALE_MARK_AS_PAID_MUTATION = gql`
  mutation (
    $id: String!
    $paidDate: String!
    $salesAmount: Float
    $salesTaxAmount: Float
  ) {
    saleMarkAsPaid(
      id: $id
      paidDate: $paidDate
      salesAmount: $salesAmount
      salesTaxAmount: $salesTaxAmount
    ) {
      sale {
        id
      }
    }
  }
`;

export default function SaleComissionPayables() {
  const [sale_mark_as_paid] = useMutation(SALE_MARK_AS_PAID_MUTATION, {
    onCompleted: (data) => {
      if (data && data.saleMarkAsPaid) {
        refetch();
      } else {
        console.error("Error marking advance as paid:", error);
      }
    },
    onError: (error) => {
      console.error("Error marking advance as paid:", error);
    },
  });

  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSeletedId] = useState("");
  const [queryFilters, setQueryFilters] = useState({ page: 1 });

  const { loading, error, data, refetch } = useQuery(ADMIN_SALES_QUERY, {
    variables: { page, status: "ALL" },
  });

  const formik = useFormik({
    initialValues: {
      paidDate: new Date().toISOString().split("T")[0],
      salesAmount: "",
      salesTaxAmount: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (showModal && selectedId) {
        sale_mark_as_paid({
          variables: {
            id: selectedId,
            paidDate: values.paidDate,
            salesAmount: parseFloat(values.salesAmount),
            salesTaxAmount: parseFloat(values.salesTaxAmount),
          },
        });
        setShowModal(false);
        refetch();
      }
    },
  });

  useEffect(() => {
    refetch(queryFilters);
  }, [queryFilters]);

  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    refetch({ page: e.selected + 1 });
  };

  if (loading) return <Loader />;
  if (error) return <h1>Something went wrong!</h1>;

  const startOfResults = data.adminSales.pageNumber
    ? (data.adminSales.pageNumber - 1) * data.adminSales.pageSize + 1
    : 0;
  const endOfResults = data.adminSales.totalEntries
    ? Math.min(
        startOfResults + data.adminSales.pageSize - 1,
        data.adminSales.totalEntries
      )
    : 0;

  const getFullName = (personnel) => {
    if (!personnel || !personnel.firstName || !personnel.lastName) {
      return "";
    }
    return `${personnel.firstName} ${personnel.lastName}`;
  };

  const currentDate = new Date().toISOString().split("T")[0];

  const handleCancel = () => {
    formik.resetForm();
    setShowModal(false);
  };

  return (
    <Layout>
      <div className="py-10 lg:pl-72">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Sale Commission Payables
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the sale commission payables
              </p>
            </div>
          </div>
        </div>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-5">
          <Filter
            setQueryFilters={setQueryFilters}
            setPage={setPage}
            filter={refetch}
          />
        </div>
        <div className="mt-8 flow-root overflow-hidden">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <table className="w-full text-left">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Date
                    <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                    <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell whitespace-nowrap"
                  >
                    Client Name
                  </th>

                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell whitespace-nowrap"
                  >
                    Personnel
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell whitespace-nowrap"
                  >
                    Sale Type
                  </th>

                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell whitespace-nowrap"
                  >
                    Contract Price
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell whitespace-nowrap"
                  >
                    Commission Rate
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell whitespace-nowrap"
                  >
                    Commission
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell whitespace-nowrap"
                  >
                    Tax %
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell whitespace-nowrap"
                  >
                    Tax Amount
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell whitespace-nowrap"
                  >
                    Net Amount
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell whitespace-nowrap"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell whitespace-nowrap"
                  >
                    Paid Date
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.adminSales.entries.map((item) => (
                  <tr id={item.id} key={item.id} className="cursor-pointer hover:bg-gray-50">
                    <td className="relative py-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap">
                      {moment(item.data).format("YYYY-MMM-DD")}
                      <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                      <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {item?.clientName}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {item?.name}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {/* {getFullName(item?.personnel)} */}
                      {item?.salesType}
                    </td>

                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {formatNumber(item?.contractPrice)}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {item?.commissionRate}%
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {formatNumber(item?.commission)}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                      { !item.paidDate ? (
                        formatNumber(item?.tax)
                      ) : (
                        formatNumber((item.taxAmount / item?.commission)*100)
                      )}                      
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {formatNumber(item.taxAmount)}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {formatNumber(item.netAmount)}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {item.status.toLowerCase() === "open" ? (
                        <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                          OPEN
                        </span>
                      ) : (
                        <span className="inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                          CLOSED
                        </span>
                      )}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {item.paidDate && moment(item.paidDate).format("YYYY-MMM-DD")}
                      <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                      <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                    </td>
                    <td>
                      {item.status.toLowerCase() === "closed" &&
                        !item.paidDate && (
                          <button
                            type="button"
                            onClick={() => {
                              setSeletedId(item.id);
                              setShowModal(true);
                              formik.setFieldValue(
                                "paidDate",
                                new Date().toISOString().split("T")[0]
                              );
                              formik.setFieldValue(
                                "salesAmount",
                                item.commission
                              );
                              formik.setFieldValue(
                                "salesTaxAmount",
                                item.taxAmount
                              );
                            }}
                            className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 whitespace-nowrap"
                          >
                            Mark as Paid
                          </button>
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {data && (
              <div className="py-4 flex flex-row items-center justify-between mt-6">
                <div className="text-sm text-gray-700">
                  <p>
                    Showing{" "}
                    <span className="font-medium">{startOfResults}</span> to{" "}
                    <span className="font-medium">{endOfResults}</span> of{" "}
                    <span className="font-medium">
                      {data.adminSales.totalEntries}
                    </span>{" "}
                    results
                  </p>
                </div>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <span>
                      <span className="sr-only">Next</span>
                      <svg
                        className="h-5 w-5 ml-2"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  }
                  previousLabel={
                    <span>
                      <svg
                        className="h-5 w-5 mr-2"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="sr-only">Previous</span>
                    </span>
                  }
                  breakLinkClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 cursor-not-allowed"
                  pageClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  containerClassName="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  activeClassName="z-10 bg-indigo-600 text-white ring-1 ring-indigo-600"
                  previousLinkClassName="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  nextLinkClassName="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={data.adminSales.totalPages}
                  renderOnZeroPageCount={null}
                />
              </div>
            )}
          </div>
        </div>

        {showModal && (
          <>
            <ModalAlert open={showModal} setOpen={setShowModal}>
              <form onSubmit={formik.handleSubmit}>
                {/* Modal content */}
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Mark as Paid
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    Please enter the date to mark as paid.
                  </p>
                  <div className="mt-4">
                    <label
                      htmlFor="paidDate"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    ></label>
                    <input
                      id="paidDate"
                      name="paidDate"
                      type="date"
                      // defaultValue={formik.values.paidDate}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.paidDate}
                      max={currentDate}
                      className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {formik.touched.paidDate && formik.errors.paidDate && (
                      <div className="text-red-600 text-sm">
                        {formik.errors.paidDate}
                      </div>
                    )}
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="salesAmount"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Sales Amount
                    </label>
                    <CurrencyInput
                      id="salesAmount"
                      name="salesAmount"
                      // defaultValue={0.0}
                      decimalsLimit={2}
                      readOnly
                      tabIndex={-1}
                      defaultValue={formik.values.salesAmount}
                      className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 cursor-not-allowed focus:outline-none focus:ring-1 select-none"
                    />
                    {formik.touched.salesAmount &&
                      formik.errors.salesAmount && (
                        <div className="text-red-600 text-sm">
                          {formik.errors.salesAmount}
                        </div>
                      )}
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="salesTaxAmount"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Sales tax amount
                    </label>
                    <CurrencyInput
                      id="salesTaxAmount"
                      name="salesTaxAmount"
                      // defaultValue={0.0}
                      decimalsLimit={2}
                      readOnly
                      tabIndex={-1}
                      defaultValue={formik.values.salesTaxAmount}
                      className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 cursor-not-allowed focus:outline-none focus:ring-1 select-none"
                    />
                    {formik.touched.salesTaxAmount &&
                      formik.errors.salesTaxAmount && (
                        <div className="text-red-600 text-sm">
                          {formik.errors.salesTaxAmount}
                        </div>
                      )}
                  </div>
                </div>
                {/* Modal actions */}
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </ModalAlert>
          </>
        )}
      </div>
    </Layout>
  );
}
