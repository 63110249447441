import * as Yup from "yup";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useFormik } from "formik";

const PROGRAM_FILTER = gql`
  query ($client: String, $program: String, $status: String) {
    programFilters(client: $client, program: $program, status: $status) {
      status
      statuses
      programs {
        id
        programName
      }
      clients {
        id
        clientName
      }
    }
  }
`;

// Validation schema
const validationSchema = Yup.object({
  client_name: Yup.string(),
  program_name: Yup.string(),
  program_status: Yup.string(),
});

export default function Filter(props) {
  const { loading, error, data, refetch } = useQuery(PROGRAM_FILTER, {
    variables: { program: "all", client: "all", status: "all" },
  });
  const [programName, setProgramName] = useState({
    label: "All",
    value: "all",
  });
  const [clientName, setClientName] = useState({ label: "All", value: "all" });
  const [statusOptions, setStausOptions] = useState([
    { label: "All", value: "all" },
    { label: "Draft", value: "draft" },
    { label: "Cancelled", value: "cancelled" },
    { label: "Active", value: "active" },
    { label: "Completed", value: "completed" },
  ]);

  const [status, setStatus] = useState({
    label: "All",
    value: "all",
  });

  const [clientNameOptions, setClientNameOptions] = useState([]);
  const [programNameOptions, setProgramNameOptions] = useState([]);

  useEffect(() => {
    // Update formik values when data is available
    if (data && data.programFilters) {
      let programs = data.programFilters.programs.map((program) => ({
        label: program.programName,
        value: program.id,
      }));
      setProgramNameOptions([...[{ label: "All", value: "all" }], ...programs]);

      let clients = data.programFilters.clients.map((program) => ({
        label: program.clientName,
        value: program.id,
      }));
      setClientNameOptions([...[{ label: "All", value: "all" }], ...clients]);
    }
  }, [data]);

  const formik = useFormik({
    initialValues: {
      client_name: "",
      program_name: "",
      program_status: "",
    },
    validationSchema,
    onSubmit: (values) => {
      console.log({
        variables: {
          client_name: values.client_name,
          program_name: values.program_name,
          program_status: values.program_status,
        },
      });
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="space-y-12">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-9 md:col-span-2 items-start">
            <div className="sm:col-span-3">
              <label
                htmlFor="react-select-client_name-input"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Client Name
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  autoFocus
                  options={clientNameOptions}
                  name="client_name"
                  id="client_name"
                  instanceId="client_name"
                  value={{
                    label: clientName.label,
                    value: clientName.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("client_name", selected.value);
                    refetch({
                      client: selected.value,
                      program: "all",
                      status: "all",
                    });
                    setClientName(selected);
                    setProgramName({ label: "All", value: "all" });
                    setStatus({ label: "All", value: "all" });
                    props.refetch({
                      page: 1,
                      client: selected.value,
                      program: "all",
                      status: "all",
                    });
                  }}
                  className="block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                {formik.touched.client_name && formik.errors.client_name ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.client_name}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="react-select-program_name-input"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Program Name
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  options={programNameOptions}
                  name="program_name"
                  id="program_name"
                  instanceId="program_name"
                  value={{
                    label: programName.label,
                    value: programName.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("program_name", selected.value);
                    // refetch({
                    //   program: selected.value,
                    //   client: clientName.value,
                    //   status: "all",
                    // });
                    setProgramName({
                      label: selected.label,
                      value: selected.value,
                    });
                    setStatus({ label: "All", value: "all" });
                    props.refetch({
                      page: 1,
                      client: clientName.value,
                      program: selected.value,
                      status: "all",
                    });
                  }}
                  className="block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                {formik.touched.client_name && formik.errors.client_name ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.client_name}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="react-select-status-input"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Status
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  options={statusOptions}
                  name="status"
                  id="status"
                  instanceId="status"
                  value={{
                    label: status.label,
                    value: status.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("program_status", selected.value);
                    setStatus(selected);
                    props.refetch({
                      page: 1,
                      client: clientName.value,
                      program: programName.value,
                      status: selected.value,
                    });
                  }}
                  className="capitalize block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {formik.touched.program_status &&
                formik.errors.program_status ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.program_status}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
