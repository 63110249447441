







export const salesMaths = (sales) => {
    if (!sales || sales.length === 0) return { sumOfAllSalesTeamPercentage: 0, sumOfSalesAndSupportPercentage: 0 };
    const sumOfAllSalesTeamPercentage = getTotalSalesPercentage(sales);
    const sumOfSalesAndSupportPercentage = getSalesAndSupport(sales);

    return {
        sumOfAllSalesTeamPercentage,
        sumOfSalesAndSupportPercentage
    };
};


const getSalesAndSupport = (sales) => {
    const sumOfSalesAndSupportPercentage = sales.reduce((total, sale) => {
        if (sale.sales_type !== "Referral Person") {
            return total + sale.sales_percentage;
            }
        return total;
    }, 0);
    return sumOfSalesAndSupportPercentage;
}

const getTotalSalesPercentage = (sales) => {
    const sumOfAllSalesTeamPercentage = sales.reduce((total, sale) => {
        return total + sale.sales_percentage;
    }, 0);
    return sumOfAllSalesTeamPercentage;
}