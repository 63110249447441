// utils/formatNumber.js
const formatNumber = (amount, minDecimals = 2, maxDecimals = 2) => {
  // Customize the formatting options as needed (e.g., currency: 'USD')
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: minDecimals, // Specify the minimum number of decimal places
    maximumFractionDigits: maxDecimals, // Specify the minimum number of decimal places
  });
  return formatter.format(amount);
};

export default formatNumber;
